import { Button } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Alert from "@material-ui/lab/Alert";
import { useCallback, useEffect, useState } from "react";
import { Colors } from "./";

export default ({ label, textToCopy }: { label: string; textToCopy: string }) => {
  const [copiedText, setCopiedText] = useState<boolean>(false);

  const copyToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(textToCopy);
    setCopiedText(true);
  }, [textToCopy]);

  useEffect(() => {
    let timer = copiedText ? setTimeout(() => setCopiedText(false), 4500) : undefined;

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [copiedText]);

  return (
    <>
      {!copiedText ? (
        <Button color="primary" endIcon={<FileCopyIcon />} fullWidth onClick={copyToClipboard} variant="contained">
          {label}
        </Button>
      ) : (
        <Alert severity="success" style={{ paddingTop: 1, paddingBottom: 1, borderRadius: "1.5rem", color: Colors.Blue, justifyContent: "center" }} variant="outlined">
          Successfully copied
        </Alert>
      )}
    </>
  );
};
